import { combineReducers } from "redux";
import { useSelector, TypedUseSelectorHook } from "react-redux";
import { RootState } from './store';

import auth from './auth/reducers';
import additional from './menu/additional/reducers';
import allotment from './menu/allotment/reducers';
import amenity from './menu/amenity/reducers';
import attribute from './menu/attribute/reducers';
import booking from './menu/booking/reducers';
import dashboard from './menu/dashboard/reducers';
import invoice from './menu/invoice/reducers';
import landingPage from './menu/landingPage/reducers';
import merchant from './menu/merchant/reducers';
import product from './menu/product/reducers';
import ratePlan from './menu/ratePlan/reducers';
import role from './menu/role/reducers';
import staff from './menu/staff/reducers';
import support from './support/reducers';
import withdrawal from './menu/withdrawal/reducers';

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector

export default combineReducers({
  auth,
  additional,
  allotment,
  amenity,
  attribute,
  booking,
  dashboard,
  invoice,
  landingPage,
  merchant,
  product,
  ratePlan,
  role,
  staff,
  support,
  withdrawal
})